import { LuzmoAuthToken } from '@polyai/common/api/resources/luzmo/types';

import { doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';
import { AnalyticsIntegration, LuzmoAuthTokenPayload } from './types';

export const getLuzmoAuthToken = (
  integrationId?: string,
  suborganisation: string = 'PolyAI',
  theme?: { [style: string]: any },
  css: string = '',
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  const payload: LuzmoAuthTokenPayload = {
    suborganisation,
    theme,
    css,
    metadata: { account_id: accountId, project_id: projectId },
  };

  if (integrationId) {
    payload.integration_id = integrationId;
  }

  return doPost<LuzmoAuthToken>(
    `accounts/${accountId}/projects/${projectId}/analytics/auth`,
    LOGGER_TYPE.ANALYTICS,
    payload,
  );
};

export const getIntegration = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<AnalyticsIntegration>(
    `accounts/${accountId}/projects/${projectId}/analytics/integrations`,
    LOGGER_TYPE.ANALYTICS,
  );
};
