import React, { useEffect, useRef, useState } from "react";

import {
  arrow,
  autoUpdate,
  AutoUpdateOptions,
  flip,
  FloatingArrow,
  inline as inlineMiddleware,
  offset,
  Placement,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { Transition } from "react-transition-group";
import { useTheme } from "styled-components";

import * as Styled from "./Tooltip.styled";

type Trigger = "hover" | "focus" | "click";

const ARROW_OFFSET = 8;

export interface TooltipProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "content"> {
  content: React.ReactNode;
  placement?: Placement;
  trigger?: Trigger[];
  delay?: {
    open?: number;
    close?: number;
  };
  inline?: boolean;
  complex?: boolean;
  disabled?: boolean;
  targetOverflow?: boolean;
  autoUpdateOptions?: AutoUpdateOptions;
  children: React.ReactNode;
  asChild?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  placement = "top",
  trigger = ["hover", "focus"],
  delay = {
    open: 0,
    close: 100,
  },
  inline = false,
  complex = false,
  disabled = false,
  targetOverflow,
  autoUpdateOptions,
  children,
  asChild,
  ...rest
}) => {
  const {
    activeColorScheme: { colors },
  } = useTheme();
  const [visible, setVisible] = useState(false);
  const [zIndex, setZIndex] = useState<number>();

  const arrowRef = useRef(null);

  const {
    x,
    y,
    strategy,
    placement: currentPlacement,
    refs,
    update,
    context,
  } = useFloating({
    open: visible,
    onOpenChange: setVisible,
    placement,
    middleware: [
      offset(ARROW_OFFSET),
      size({
        apply: ({ rects, elements }) => {
          Object.assign(elements.floating.style, {
            whiteSpace: `${
              rects.floating.width < Styled.TOOLTIP_MAX_WIDTH
                ? "nowrap"
                : "normal"
            }`,
            minWidth: `${
              rects.floating.width < Styled.TOOLTIP_MAX_WIDTH
                ? "fit-content"
                : "auto"
            }`,
          });
        },
      }),
      inline && inlineMiddleware(),
      flip({ fallbackStrategy: "initialPlacement" }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: (ref, float, update) => {
      const cleanup = autoUpdate(ref, float, update, autoUpdateOptions);
      return cleanup;
    },
  });

  useEffect(() => {
    if (visible) {
      update();
      setZIndex(Styled.DEFAULT_Z_INDEX);
    } else {
      // avoid potential tooltip masking as it exits by delaying z-index update
      const resetDelay = delay.close! + 200;
      const timer = setTimeout(() => {
        setZIndex(-1);
      }, resetDelay);

      return () => clearTimeout(timer);
    }
  }, [visible, content, update, delay.close]);

  const hover = useHover(context, {
    enabled: trigger.includes("hover"),
    delay,
  });
  const focus = useFocus(context, { enabled: trigger.includes("focus") });
  const click = useClick(context, { enabled: trigger.includes("click") });
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: "tooltip",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
    click,
  ]);

  return (
    <>
      {asChild ? (
        React.isValidElement(children) &&
        React.cloneElement(
          children,
          getReferenceProps({
            ref: refs.setReference,
          })
        )
      ) : (
        <Styled.TooltipTrigger
          ref={refs.setReference}
          $disabled={disabled}
          $inline={inline}
          $targetOverflow={targetOverflow}
          {...getReferenceProps()}
        >
          {children}
        </Styled.TooltipTrigger>
      )}

      {!disabled && (
        <Transition in={visible} timeout={0}>
          {(state) => (
            <Styled.TooltipWrapper
              {...getFloatingProps()}
              ref={refs.setFloating}
              $complex={complex}
              $disabled={disabled}
              $placement={currentPlacement}
              $state={state}
              $strategy={strategy}
              $x={x}
              $y={y}
              $zIndex={zIndex}
              aria-hidden={!visible}
              role="tooltip"
              {...rest}
            >
              <Styled.TooltipContent>{content}</Styled.TooltipContent>
              <FloatingArrow
                ref={arrowRef}
                context={context}
                fill={colors.backgroundInverse}
                height={5}
                staticOffset={
                  placement.includes("start") || placement.includes("end")
                    ? 4
                    : 0
                }
                tipRadius={2}
                width={10}
              />
            </Styled.TooltipWrapper>
          )}
        </Transition>
      )}
    </>
  );
};

export default Tooltip;
